import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const MenuImage = ({ url }) => {
  return (
    <Worker workerUrl="/pdf.worker.min.js">
      {!!url ? <Viewer fileUrl={url} defaultScale="PageFit" /> : <>No Menu Available</>}
    </Worker>
  );
};

export default MenuImage;
