import React from "react";
import logo from "../../assets/images/logo/footer-logo.svg";
import instagram from "../../assets/images/instagram.svg";
import twitter from "../../assets/images/twitter.svg";
import tiktok from "../../assets/images/tiktok.svg";
import facebook from "../../assets/images/facebook.svg";
import youtube from "../../assets/images/youtube.svg";
import location from "../../assets/images/location.svg";
import call from "../../assets/images/call.svg";
import mail from "../../assets/images/mail.svg";
import food1 from "../../assets/images/food-1.png";
import food2 from "../../assets/images/food-2.png";
import food3 from "../../assets/images/food-3.png";
import food4 from "../../assets/images/food-4.png";
import food5 from "../../assets/images/food-5.png";
import food6 from "../../assets/images/food-6.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-primaryLight pt-12">
      <div className="container">
        <div className="flex justify-start md:justify-between border-b border-[#D9D9D9] pb-10 flex-wrap">
          <div className="flex items-center gap-6">
            <img src={logo} alt="logo" />
            <p className="max-w-[303px] text-secondary font-sora">
              Flavour Heaven is more than just a restaurant - it's an
              experience!
            </p>
          </div>
          <div className="pt-10 md:p-0">
            <p className="text-secondary font-semibold text-[18px] mb-4 leading-none text-left">
              Follow Us
            </p>
            <div className="flex gap-6">
              <a
                href={"https://www.instagram.com/flavourheaven.fi"}
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} alt="instagram" />
              </a>
              <a
                href={"https://x.com/FlavourHeaven2"}
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter} alt="twitter" />
              </a>
              <a
                href={"https://www.tiktok.com/@flavourheavean2"}
                target="_blank"
                rel="noreferrer"
              >
                <img src={tiktok} alt="tiktok" />
              </a>
              <a
                href={"https://www.facebook.com/profile.php?id=61561816495746"}
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="facebook" />
              </a>
              <a
                href={"https://www.youtube.com/@FlavourHeaven-Fi"}
                target="_blank"
                rel="noreferrer"
              >
                <img src={youtube} alt="youtube" />
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap border-b border-[#D9D9D9] py-10">
          <div className="2xl:w-[22%] sm:w-[50%] w-full text-left">
            <h3 className="text-blackShade1 font-semibold text-[18px] leading-none mb-4">
              Flavour Heaven
            </h3>
            <ul>
              <li className="mb-3 leading-[1.3] font-semibold">
                <Link
                  className="text-secondary transition-all duration-300 hover:text-primary"
                  to="/lunch-menu"
                >
                  Lunch Menu
                </Link>
              </li>
              <li className="mb-3 leading-[1.3] font-semibold">
                <Link
                  className="text-secondary transition-all duration-300 hover:text-primary"
                  to="/a-la-carte-menu"
                >
                  A La Carte Menu
                </Link>
              </li>
              <li className="mb-3 leading-[1.3] font-semibold">
                <Link
                  className="text-secondary transition-all duration-300 hover:text-primary"
                  to="/drinks"
                >
                  Drinks
                </Link>
              </li>
              <li className="mb-3 leading-[1.3] font-semibold">
                <Link
                  className="text-secondary transition-all duration-300 hover:text-primary"
                  to="/about-us"
                >
                  About Us
                </Link>
              </li>
              <li className="leading-[1.3] font-semibold">
                <Link
                  className="text-secondary transition-all duration-300 hover:text-primary"
                  to="/contact-us"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="2xl:w-[22%] sm:w-[50%] w-full text-left pt-10 sm:p-0">
            <h3 className="text-blackShade1 font-semibold text-[18px] leading-none mb-4">
              Opening Hours
            </h3>
            <ul>
              <li className="text-secondary mb-2 leading-none font-semibold">
                Monday to Friday
              </li>
              <li className="text-primary mb-4 leading-none font-bold">
                11:00 - 21:00
              </li>
            </ul>
            <ul>
              <li className="text-secondary mb-2 leading-none font-semibold">
                Saturday and Sunday
              </li>
              <li className="text-primary leading-none font-bold">
                11:00 - 21:00
              </li>
            </ul>
          </div>
          <div className="2xl:w-[34%] md:w-[50%] w-full text-left 2xl:p-0 pt-10">
            <h3 className="text-blackShade1 font-semibold text-[18px] leading-none mb-4">
              Get in touch
            </h3>
            <ul>
              <li className="text-secondary mb-3 leading-none font-semibold flex items-start gap-3 justify-center md:justify-start">
                <img src={location} alt="location" />
                <span className="block leading-[1.3] lg:max-w-[69%] md:max-w-[60%]">
                  Meritorni, 1st floor, Haapaniemenkatu 7-9 B, 00530 Helsinki
                </span>
              </li>
              <li className="mb-3">
                <a
                  href="tel:(+358)207314020"
                  className="text-secondary leading-none font-semibold transition-all hover:text-primary flex items-center gap-3 justify-start"
                >
                  <span className="flex items-center justify-center w-5 h-5">
                    <img
                      src={call}
                      alt="call"
                      className="w-full h-full object-contain"
                    />
                  </span>
                  (+358) 20 7314020
                </a>
              </li>
              <li>
                <a
                  href="mailto:info@flavourheaven.fi"
                  className="text-secondary leading-none font-semibold transition-all hover:text-primary flex items-center gap-3 justify-start"
                >
                  <span className="flex items-center justify-center w-5 h-5">
                    <img
                      src={mail}
                      alt="mail"
                      className="w-full h-full object-contain"
                    />
                  </span>
                  info@flavourheaven.fi
                </a>
              </li>
            </ul>
          </div>
          <div className="2xl:w-[22%] md:w-[50%] w-full text-left 2xl:p-0 pt-10">
            <h3 className="text-blackShade1 font-bold text-[18px] leading-none mb-4">
              Instagram Gallery
            </h3>
            <div className="flex flex-wrap gap-1 w-[278px] 2xl:w-auto mx-0">
              <img src={food1} alt="food" />
              <img src={food2} alt="food" />
              <img src={food3} alt="food" />
              <img src={food4} alt="food" />
              <img src={food5} alt="food" />
              <img src={food6} alt="food" />
            </div>
          </div>
        </div>
        <div className="py-4">
          <ul className="flex flex-wrap justify-center gap-6">
            <li className="relative text-gray text-[14px] after:content-'' after:absolute after:h-full after:bg-[#D9D9D9] after:w-[1px] after:ml-[12px]">
              Copyright © 2024 Flavour Heaven
            </li>
            <li className="relative text-gray text-[14px] after:content-'' after:absolute after:h-full after:bg-[#D9D9D9] after:w-[1px] after:ml-[12px]">
              All Rights Reserved
            </li>
            <li className="relative text-[14px] after:content-'' after:absolute after:h-full after:bg-[#D9D9D9] after:w-[1px] after:ml-[12px]">
              <Link
                to="/terms-and-condition"
                className="text-gray hover:text-primary transition-all"
              >
                Terms and Conditions
              </Link>
            </li>
            <li className="text-[14px]">
              <Link
                to="/privacy-policy"
                className="text-gray hover:text-primary transition-all"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
