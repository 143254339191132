import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import book1 from "assets/images/cover/book-1.jpg";
import book2 from "assets/images/cover/book-2.jpg";
import book3 from "assets/images/cover/book-3.jpg";
import DatePicker from "react-datepicker";
import axios from "axios";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import CustomTimePicker from "./CustomTimePicker";
import { formatDateDDMMYYYY, toTwoDigits, validateDate } from "utils";
import useOutsideClick from "hooks/useOutsideClick";
import { useLocation } from "react-router-dom";
import Modal from "./Modal";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[A-Za-z ]+$/, "Please enter valid full name")
    .test(
      "no-whitespace",
      "Input cannot be empty or contain only whitespace",
      (value) => {
        return /\S/.test(value);
      }
    )
    .required("Please enter full name"),
  number_of_persons: Yup.string()
    .min(1, "At least 1 person is required")
    .matches(/^[0-9]+$/, "Please enter valid number of persons")
    .test(
      "zero-validation",
      "Please enter a valid number of persons",
      function (value) {
        return value && Number(value) > 0;
      }
    )
    .required("Please enter total number of people"),
  phone: Yup.string()
    .matches(
      /^[+]?[(]?[0-9]{1,4}[)]?[-\s0-9]*$/,
      "Phone number length should be maximum 15 digits"
    )
    .min(7, "Phone number length must be at least 7 digits")
    .max(15, "Phone number length should be maximum 15 digits")
    .required("Please enter valid phone number"),
  reservation_date: Yup.date()
    .required("Please select date")
    .typeError("Invalid date"),
  captcha_token: Yup.string().required("Please verify captcha"),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    )
    .email("Invalid email format")
    .required("Please enter email"),
  reservation_time: Yup.string()
    .test("time-validation", function (value) {
      if (!value) return true;

      const dayOfWeek =
        this.parent.reservation_date?.getDay() || new Date().getDay();

      if ((dayOfWeek === 0 || dayOfWeek === 6) && value < "11:00") {
        return this.createError({
          message: "Weekend slots must be within 11:00 to 21:00",
        });
      } else if (dayOfWeek >= 1 && dayOfWeek <= 5 && value < "11:00") {
        return this.createError({
          message: "Weekday slots must be within 11:00 to 21:00",
        });
      } else if (value > "20:59") {
        return this.createError({
          message: "Time slot cannot be later than 21:00",
        });
      }

      return true;
    })
    .required("Please provide time slot"),
});

const BookTableSection = () => {
  const captchaRef = useRef(null);
  const bookATableRef = useRef(null);
  const messageRef = useRef(null);
  const location = useLocation();
  const custom_time_pickerRef = useRef(null);
  const currentDateTime = new Date();
  const [bookingLoadingState, setBookingLoadingState] = useState(false);
  const [openTimePicker, setOpenTimePicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setIsModalOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState({
    hour: currentDateTime.getHours(),
    minute: 0,
  });

  useEffect(() => {
    if (location.hash === "#book_a_table" && bookATableRef.current) {
      bookATableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const initialValues = {
    name: "",
    number_of_persons: 2,
    phone: "",
    reservation_date: currentDateTime,
    email: "",
    reservation_time: `${currentDateTime.getHours()}:00`,
    captcha_token: null,
    message: "",
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "json",
  };

  const onSubmit = async (values) => {
    try {
      setBookingLoadingState(true);
      setIsLoading(true);
      const captcha_token = captchaRef.current.getValue();

      const response = await axios.post(
        "https://flavourheaven.fi/flavourheaven-api/reservation.php",
        {
          ...values,
          captcha_token,
          reservation_date: formatDateDDMMYYYY(values.reservation_date),
          type: "reservation",
        },
        {
          headers: headers,
        }
      );
      if (response.status === 200 && response.data?.status === true) {
        // toast.success("Booking confirmation has been sent to your email");
        setIsModalOpen(true);
        captchaRef.current.reset();
        resetForm();
        setSelectedTime({
          hour: currentDateTime.getHours(),
          minute: 0,
        });
        setFieldValue("reservation_time", `${currentDateTime.getHours()}:00`);
        setFieldValue("message", "");
        messageRef.current.innerHTML = "";
        setBookingLoadingState(false);
        setIsLoading(false);
      } else {
        setBookingLoadingState(false);
        setIsLoading(false);
      }
    } catch (err) {
      setBookingLoadingState(false);
      setIsLoading(false);
      captchaRef.current.reset();
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    touched,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    setFieldValue(
      "reservation_time",
      `${toTwoDigits(selectedTime.hour)}:${toTwoDigits(selectedTime.minute)}`
    );
  }, [selectedTime]);

  useOutsideClick(custom_time_pickerRef, () => setOpenTimePicker(false));

  let [isOpen, setIsOpen] = useState(true);

  return (
    <section
      className="3xl:pt-52 2xl:pt-44 xl:pt-32 lg:pt-20 md:pt-16 pt-14"
      id="book_a_table"
      ref={bookATableRef}
    >
      <div className="container">
        <h3 className="capitalize text-blackShade1 2xl:text-5xl xl:text-4xl sm:text-3xl text-2xl font-bold text-center mb-2">
          <span className="text-primary">reserve</span> a table
        </h3>
        <span className="2xl:mb-12 lg:mb-8 mb-6 block text-center text-lg font-medium text-gray">
          Get your table reserved in advance at your comfortable time
        </span>
        <div className="bg-primaryTint1 2xl:p-12 xl:p-9 lg:p-7 md:p-6 p-5 2xl:rounded-[32px] xl:rounded-3xl rounded-2xl flex lg:flex-row flex-col items-center justify-between 3xl:gap-20 2xl:gap-16 xl:gap-10 lg:gap-9 md:gap-7 gap-5">
          <div className="xl:w-[43.5%] lg:w-[41%] w-full">
            <div className="flex items-start gap-3 justify-between">
              <div className="w-[31.4%] xl:rounded-xl rounded-lg overflow-hidden">
                <img src={book1} alt="images" className="w-full object-cover" />
              </div>
              <div className="flex flex-col gap-3 w-[29.4%] mt-6">
                <div className="w-full xl:rounded-xl rounded-lg overflow-hidden">
                  <img
                    src={book2}
                    alt="images"
                    className="w-full object-cover"
                  />
                </div>
                <div className="w-full xl:rounded-xl rounded-lg overflow-hidden">
                  <img
                    src={book3}
                    alt="images"
                    className="w-full object-cover"
                  />
                </div>
              </div>
              <div className="w-[31.4%] xl:rounded-xl rounded-lg overflow-hidden mt-14">
                <img src={book1} alt="images" className="w-full object-cover" />
              </div>
            </div>
          </div>
          <div className="xl:w-[56.5%] lg:w-[59%] w-full">
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="grid sm:grid-cols-2 grid-cols-1 3xl:gap-x-12 2xl:gap-x-10 xl:gap-x-8 lg:gap-x-7 md:gap-x-6 gap-x-5 gap-y-5 mb-5">
                <div>
                  <label
                    htmlFor="name"
                    className="text-sm block mb-2 text-gray capitalize"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter your full name"
                    className="2xl:px-4 2xl:py-3 px-3 py-2 bg-white border border-grayTint2 2xl:rounded-xl rounded-lg text-base text-grayTint3 w-full outline-none focus:border-primary transition-all duration-300"
                  />
                  {errors.name && touched.name ? (
                    <p className="text-rose-500 text-sm">{errors.name}</p>
                  ) : null}
                </div>
                <div>
                  <label
                    htmlFor="number_of_persons"
                    className="text-sm block mb-2 text-gray capitalize"
                  >
                    Number of Persons
                  </label>
                  <input
                    type="text"
                    id="number_of_persons"
                    name="number_of_persons"
                    value={values.number_of_persons}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter total number of persons"
                    className="2xl:px-4 2xl:py-3 px-3 py-2 bg-white border border-grayTint2 2xl:rounded-xl rounded-lg text-base text-grayTint3 w-full outline-none focus:border-primary transition-all duration-300"
                  />
                  {errors.number_of_persons && touched.number_of_persons ? (
                    <p className="text-rose-500 text-sm">
                      {errors.number_of_persons}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="text-sm block mb-2 text-gray capitalize"
                  >
                    Phone number
                  </label>
                  <input
                    type="number"
                    id="phone"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={15}
                    placeholder="Enter your phone number"
                    className="2xl:px-4 2xl:py-3 px-3 py-2 bg-white border border-grayTint2 2xl:rounded-xl rounded-lg text-base text-grayTint3 w-full outline-none focus:border-primary transition-all duration-300"
                  />
                  {errors.phone && touched.phone ? (
                    <p className="text-rose-500 text-sm">{errors.phone}</p>
                  ) : null}
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="text-sm block mb-2 text-gray capitalize"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter your email"
                    className="2xl:px-4 2xl:py-3 px-3 py-2 bg-white border border-grayTint2 2xl:rounded-xl rounded-lg text-base text-grayTint3 w-full outline-none focus:border-primary transition-all duration-300"
                  />
                  {errors.email && touched.email ? (
                    <p className="text-rose-500 text-sm">{errors.email}</p>
                  ) : null}
                </div>
                <div className="react-flavour-heaven-datepicker">
                  <label
                    htmlFor="reservation_date"
                    className="text-sm block mb-2 text-gray capitalize"
                  >
                    select date
                  </label>
                  {/* <DatePicker
                    className="flavour-heaven-input 2xl:px-4 2xl:py-3 px-3 py-2 bg-white border border-grayTint2 2xl:rounded-xl rounded-lg text-base text-grayTint3 w-full outline-none focus:border-primary transition-all duration-300"
                    calendarClassName="flavour-heaven-calendar"
                    selected={values.reservation_date}
                    name="reservation_date"
                    value={values.reservation_date}
                    onBlur={handleBlur}
                    placeholderText="Select Date"
                    // dateFormat={"dd/MM/YYYY"}
                    minDate={new Date().toISOString().split("T")[0]}
                    onChange={(date) => {
                      !validateDate(date)
                        ? setFieldValue("reservation_date", date)
                        : setFieldValue("reservation_date", new Date());
                    }}
                  /> */}
                  <DatePicker
                    className="flavour-heaven-input 2xl:px-4 2xl:py-3 px-3 py-2 bg-white border border-grayTint2 2xl:rounded-xl rounded-lg text-base text-grayTint3 w-full outline-none focus:border-primary transition-all duration-300"
                    calendarClassName="flavour-heaven-calendar"
                    value={values.reservation_date}
                    selected={values.reservation_date}
                    name="reservation_date"
                    onBlur={handleBlur}
                    onChange={(date) => setFieldValue("reservation_date", date)}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date().toISOString().split("T")[0]}
                    placeholderText="Please select date"
                  />

                  {errors.reservation_date &&
                  (touched.reservation_time || touched.reservation_date) ? (
                    <p className="text-rose-500 text-sm">
                      {errors.reservation_date}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label
                    htmlFor="reservation_time"
                    className="text-sm block mb-2 text-gray capitalize"
                  >
                    Select Time Slot
                  </label>
                  <div
                    name="reservation_time"
                    id="reservation_time"
                    onClick={() => setOpenTimePicker(!openTimePicker)}
                    className="timepicker-input 2xl:px-4 2xl:py-3 px-3 py-2 bg-white border border-grayTint2 2xl:rounded-xl rounded-lg text-base text-grayTint3 w-full outline-none focus:border-primary transition-all duration-300"
                  >{`${toTwoDigits(selectedTime.hour)}:${toTwoDigits(
                    selectedTime.minute
                  )}`}</div>
                  {openTimePicker ? (
                    <CustomTimePicker
                      dateWeekDay={
                        values.reservation_date?.getDay() || new Date().getDay()
                      }
                      ref={custom_time_pickerRef}
                      setSelectedTime={setSelectedTime}
                      selectedTime={selectedTime}
                    />
                  ) : null}
                  {errors.reservation_time &&
                  (touched.reservation_time || touched.reservation_date) ? (
                    <p className="text-rose-500 text-sm">
                      {errors.reservation_time}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="mb-5 relative">
                <label
                  htmlFor="message"
                  className="text-sm flex items-center mb-2 text-gray capitalize"
                >
                  Message (Optional)
                </label>
                <div className="relative h-full">
                  <textarea
                    ref={messageRef}
                    id="message"
                    name="message"
                    placeholder="Write your message"
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={255}
                    rows="4"
                    cols="50"
                    className=" 2xl:px-4 2xl:py-3 px-3 py-2 pb-7 bg-white border border-grayTint2 2xl:rounded-xl rounded-lg text-base text-grayTint3 w-full outline-none focus:border-primary transition-all duration-300 resize-none"
                  />
                  <div
                    className={`text-xs bg-white px-2 text-grayTint3 absolute bottom-4 right-4 z-10`}
                  >
                    {!!messageRef?.current?.innerHTML
                      ? `${messageRef?.current?.innerHTML?.length} /
                        ${messageRef.current.maxLength}`
                      : `0/255`}
                  </div>
                </div>
              </div>
              <div className="mb-11">
                <ReCAPTCHA
                  sitekey="6LehOQgqAAAAAKcitNvtzA2g25FpbvGEaDOdqIuB"
                  name="captcha_token"
                  id="captcha_token"
                  onBlur={handleBlur}
                  ref={captchaRef}
                  onChange={(e) =>
                    setFieldValue(
                      "captcha_token",
                      captchaRef.current.getValue()
                    )
                  }
                />
                {errors.captcha_token && touched.captcha_token ? (
                  <p className="text-rose-500 text-sm">
                    {errors.captcha_token}
                  </p>
                ) : null}
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  disabled={bookingLoadingState}
                  className="relative lg:h-[55px] h-[45px] flex items-center justify-center md:w-3/5 w-full 2xl:py-4 lg:py-3 py-2 2xl:px-6 px-5 bg-primary border border-primary text-base font-semibold text-blackShade1 lg:rounded-xl rounded-lg transition-all duration-300 hover:bg-secondary hover:border-secondary hover:text-white"
                >
                  {isLoading ? (
                    <div className="spinner"></div>
                  ) : (
                    <span>Book Your Table</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal openModal={modalOpen} closeModal={() => {}}>
        <h4 className="md:text-xl text-lg font-semibold text-primary mb-3 text-center">
          Reservation Request Received
        </h4>
        <span className="text-base text-blackShade1 block text-center md:mb-7 mb-5">
          Thank you for your reservation request. Our team is currently
          processing your request, and we will send you a booking confirmation
          email shortly
        </span>
        <button
          onClick={() => setIsModalOpen(false)}
          className="mx-auto w-4/12 flex items-center justify-center bg-primary text-base font-semibold text-blackShade1 text-center p-3 lg:rounded-xl rounded-lg transition-all duration-300 hover:bg-secondary hover:border-secondary hover:text-white"
        >
          Ok
        </button>
      </Modal>
    </section>
  );
};

export default BookTableSection;
