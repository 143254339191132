import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logoImg from "../../assets/images/logo/logo.svg";
import { headerLink } from "../../constants";

const Header = () => {
	const [isCollapsed, setIscollapsed] = useState(false);

	const ToggleHeader = () => {
		isCollapsed === true ? setIscollapsed(false) : setIscollapsed(true);
	};
	return (
		<div className="sticky top-0 z-50">
			<div className="bg-primary py-2">
				<div className="container">
					<span className="block md:text-base text-sm text-blackShade1 text-center">We serve a lunch buffet and à la carte options from 11 AM to 3 PM, after which we offer only à la carte. We also serve South Indian cuisine.</span>
				</div>
			</div>
			<header className="bg-white py-5 z-50 shadow-[0px_2px_12px_0px_#FB9B4440;]">
				<div className="container flex items-center justify-between">
					<Link to="/" className="2xl:w-[298px] md:w-[250px] w-[200px] relative z-10">
						<img src={logoImg} alt="Flavour Heaven" className="w-full object-contain" />
					</Link>
					<div className={`flex xl:items-center 3xl:gap-[42px] 2xl:gap-9 xl:gap-6 gap-5 xl:pt-0 pt-[75px] xl:px-0 lg:pl-[48px] md:pl-[40px] sm:pl-[34px] pl-[16px] xl:flex-row flex-col xl:justify-end xl:relative fixed xl:w-auto w-full xl:h-auto h-full bg-white xl:top-0 sm:top-[65px] top-[96px] right-0 transition-all duration-500 ${isCollapsed === true ? "transalate-x-0 collapsed" : "xl:translate-x-0 translate-x-full"}`}>
						{headerLink.map((headerNavItem, headerNavIndex) => {
							return (
								<NavLink key={headerNavIndex} to={headerNavItem.navPath}>
									{({ isActive }) => <span className={`block capitalize text-base font-semibold leading-[1.3] transition-all hover:text-primary ${isActive ? "text-primary" : "text-blackShade1"}`}>{headerNavItem.navName}</span>}
								</NavLink>
							);
						})}
						<Link type="button" to={`/#book_a_table`} onClick={() => setIscollapsed(false)} className="inline-flex items-center justify-center xl:w-auto lg:w-2/12 md:w-3/12 w-6/12 py-1.5 px-5 h-[42px] bg-primary border border-primary text-base font-semibold text-blackShade1 lg:rounded-xl rounded-lg transition-all duration-300 hover:bg-secondary hover:border-secondary hover:text-white text-center">
							Book A Table
						</Link>
					</div>
					<button onClick={ToggleHeader} className="xl:hidden block relative z-10">
						<span className={`w-6 h-[2px] bg-primary block my-1 transition-all duration-300 ${isCollapsed ? "translate-y-1 rotate-45" : ""}`}></span>
						<span className={`w-4 h-[2px] ml-auto bg-primary block my-1 transition-all duration-300 ${isCollapsed ? "opacity-0" : ""}`}></span>
						<span className={`w-6 h-[2px] bg-primary block my-1 transition-all duration-300 ${isCollapsed ? "-translate-y-2 -rotate-45" : ""}`}></span>
					</button>
				</div>
			</header>
		</div>
	);
};

export default Header;
