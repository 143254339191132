import React from 'react';
import { MainLayout } from '../layout/MainLayout/MainLayout';
import Banner from '../components/common/Banner';
import MenuImage from '../components/common/MenuImage';
import bgImg from '../assets/images/flavour-banner.png';
import useFetchMenus from 'hooks/useFetchMenu';

const LunchMenu = () => {
  const { loading, menus } = useFetchMenus();

  return (
    <MainLayout>
      <Banner heading="Lunch Menu" url={bgImg} gradientClass="bg-lunchBg" />
      <div className="container 2xl:py-[120px] lg:py-20 md:py-10 py-6 flex justify-center">
        <div className="w-full max-w-[1024px]">
          <div className="2xl:p-12 xl:p-9 lg:p-7 md:p-6 md:bg-grayTint1 2xl:rounded-[32px] xl:rounded-3xl rounded-2xl">
            {loading ? null : <MenuImage url={menus['buffet_menu_file']} />}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default LunchMenu;
