import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const useFetchMenus = () => {
  const [loading, setLoading] = useState(false);
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    const getMenus = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          "https://flavourheaven.fi/flavourheaven-api/reservation.php",
          {
            token: "E3VjEbKgIpsSBnALAEB4iEJM1AFZ2fQfzNg2c2Xf6cRBSWxEBi",
            type: "get_menu_pdf",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "json",
            },
          }
        );
        setMenus(response?.data?.uploaded_files);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    getMenus();
  }, []);

  return { loading, menus };
};
export default useFetchMenus;
