import React from "react";

const Banner = ({ heading, url, gradientClass }) => {
	return (
		<div className="relative h-[400px]">
			<div className={`absolute z-10 w-full h-full top-0 left-0 ${gradientClass}`}></div>
			<div className="h-full bg-cover bg-center" style={{ backgroundImage: `url(${url})` }}>
				<div className="flex items-center justify-center h-full relative z-20">
					<h2 className="banner-text relative 3xl:text-[64px] 2xl:text-6xl xl:text-5xl lg:text-4xl text-3xl text-white font-bold py-3 pt-[22px] px-10 xl:rounded-3xl rounded-2xl border-2 border-primary">{heading}</h2>
				</div>
			</div>
		</div>
	);
};

export default Banner;
