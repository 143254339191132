import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import React from "react";

const Modal = ({ children, openModal }) => {
	return (
		<div>
			<Dialog open={openModal} onClose={() => {}} className="relative z-50">
				<DialogBackdrop className="fixed inset-0 bg-black/30" />
				<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
					<DialogPanel className="max-w-lg bg-white p-6 shadow-popUp rounded-xl">
						{children}
					</DialogPanel>
				</div>
			</Dialog>
		</div>
	);
};

export default Modal;
