import React, { forwardRef } from "react";
import { toTwoDigits } from "utils";

const CustomTimePicker = forwardRef(
  ({ dateWeekDay, selectedTime, setSelectedTime }, ref) => {
    return (
      <>
        <div ref={ref} className="custom__time__input_selector z-20 shadow-lg">
          <div className="hours__time_input time-picker">
            {Array(24)
              .fill()
              .map((_, hour) => {
                if (dateWeekDay >= 1 && dateWeekDay <= 5) {
                  if (hour < 11 || hour > 21) {
                    return null;
                  }
                } else {
                  if (hour < 11 || hour > 21) {
                    return null;
                  }
                }
                return (
                  <span
                    className={`hover:bg-primary time__picker_input_indices transition-all duration-300 text-base p-1 w-9 h-9 flex items-center justify-center rounded-lg [&:not(:last-child)]:mb-1  ${
                      selectedTime.hour === hour ? "bg-primary" : "bg-white"
                    }`}
                    onClick={() =>
                      setSelectedTime((prev) => ({ ...prev, hour: hour }))
                    }
                  >
                    {toTwoDigits(hour)}
                  </span>
                );
              })}
          </div>
          <div className="minutes__time_input time-picker">
            {Array(60)
              .fill()
              .map((_, minute) => {
                if (minute > 0 && minute % 5 !== 0) {
                  return null;
                }
                return (
                  <span
                    className={`hover:bg-primary time__picker_input_indices transition-all duration-300 text-base p-1 w-9 h-9 flex items-center justify-center rounded-lg [&:not(:last-child)]:mb-1 ${
                      selectedTime.minute === minute ? "bg-primary" : "bg-white"
                    }`}
                    onClick={() =>
                      setSelectedTime((prev) => ({
                        ...prev,
                        minute: minute,
                      }))
                    }
                  >
                    {toTwoDigits(minute)}
                  </span>
                );
              })}
          </div>
        </div>
      </>
    );
  }
);

export default CustomTimePicker;
