import { AdminLayout } from "layout/MainLayout/AdminLayout";
import logoImg from "assets/images/logo/logo.svg";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { ReactComponent as DeleteIcon } from "assets/images/icons/delete.svg";

const menus = [
  {
    id: 1,
    label: "Lunch",
    key: "buffet_menu_file",
    fileName: "Final_buffet_menu.pdf",
  },
  {
    id: 2,
    label: "A La Carte",
    key: "food_menu_file",
    fileName: "Final_food_menu.pdf",
  },
  {
    id: 3,
    label: "Drinks",
    key: "drink_menu_file",
    fileName: "Final_drink_menu.pdf.pdf",
  },
];

const Admin = () => {
  const [selectedFileName, setSelectedFileName] = useState({});
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const tokenRef = useRef(null);
  const fileInputRefs = useRef({});

  const handleFileChange = (event, key) => {
    if (event.target.files.length > 0) {
      if (event.target.files[0].type === "application/pdf") {
        setSelectedFileName((prev) => ({
          ...prev,
          [key]: event.target.files[0],
        }));
        setError((prev) => ({
          ...prev,
          [key]: false,
          empty: false,
        }));
      } else {
        setError((prev) => ({
          ...prev,
          [key]: true,
        }));
      }
    }
  };

  const handleSubmitMenu = async () => {
    try {
      if (Object?.keys(selectedFileName)?.length === 0) {
        setError({ empty: true });
      } else if (!tokenRef.current.value) {
        setError({ tokenError: true });
      } else {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("buffet_menu_file", selectedFileName.buffet_menu_file);
        formData.append("drink_menu_file", selectedFileName.drink_menu_file);
        formData.append("food_menu_file", selectedFileName.food_menu_file);
        formData.append("type", "menu_pdf");
        formData.append("token", tokenRef.current.value);

        const response = await axios.post(
          "https://flavourheaven.fi/flavourheaven-api/reservation.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "json",
            },
          }
        );
        if (response.status === 200 && response.data?.status === true) {
          setIsLoading(false);
          setSelectedFileName({});
          setError({});
          menus.map((menu) => (fileInputRefs.current[menu.key].value = ""));
          toast.success("Menu uploaded successfully.");
        } else if (response.data?.status === false) {
          toast.error(response?.data?.message);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setIsLoading(false);
      toast.error(err);
    }
  };

  return (
    <AdminLayout pageWrapper={"flex flex-col min-h-screen"}>
      <div className="flex flex-col justify-center items-center p-4 flex-1 overflow-y-auto">
        <Link
          to="/"
          className="2xl:w-[298px] md:w-[250px] w-[200px] relative z-10 pb-10"
        >
          <img
            src={logoImg}
            alt="Flavour Heaven"
            className="w-full object-contain"
          />
        </Link>
        <div className="xl:w-[30%] lg:w-[60%] md:w-[80%] sm:w-full w-full bg-primaryLight p-12 2xl:rounded-[32px] xl:rounded-3xl rounded-2xl gap-4 flex flex-col">
          {menus.map((menu) => (
            <div
              className="flex items-center flex-col justify-center gap-3 w-full"
              key={menu.id}
            >
              <p className="font-bold text-lg">{menu.label} Menu</p>
              <div className="flex items-center justify-center w-full pb-5 ">
                <label
                  for={menu.key}
                  className={`${
                    !!error?.[menu.key] ? "border-red-600 border-2" : ""
                  } flex flex-col items-center justify-center h-20 border w-full border-gray-300 border-dashed rounded-3xl cursor-pointer bg-white dark:hover:bg-gray-800 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 px-5`}
                >
                  <div className="flex flex-row gap-4 items-center justify-center w-full text-center">
                    {!!selectedFileName?.[menu.key] && !error?.[menu.key] ? (
                      <DeleteIcon
                        width={20}
                        height={20}
                        title="Delete"
                        className="delete__icon_stroke min-w-6 min-h-6"
                        onClick={(e) => {
                          e.preventDefault();
                          fileInputRefs.current[menu.key].value = "";
                          setSelectedFileName((prev) => {
                            const { [menu.key]: omit, ...rest } = prev;
                            return rest;
                          });
                        }}
                      />
                    ) : (
                      <svg
                        className="w-6 h-6 min-w-6 min-h-6 text-gray-500 dark:text-gray-400 justify-center flex"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                    )}

                    <p className="flex text-sm text-center justify-center text-gray-500 dark:text-gray-400 truncate max-w-96">
                      <span
                        className={`font-semibold truncate text-center ${
                          !!error?.[menu.key] ? "text-red-600" : ""
                        }`}
                      >
                        {!!selectedFileName?.[menu.key]?.name
                          ? selectedFileName?.[menu.key]?.name
                          : !!error?.[menu.key]
                          ? "Only PDFs are allowed!"
                          : `Click to Upload ${menu.label} Menu in PDF`}
                      </span>
                    </p>
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    key={menu.key}
                    id={menu.key}
                    ref={(el) => (fileInputRefs.current[menu.key] = el)}
                    onChange={(e) => handleFileChange(e, menu.key)}
                    accept="application/pdf"
                  />
                </label>
              </div>
            </div>
          ))}
          <div>
            <input
              type="token"
              id="token"
              name="token"
              ref={tokenRef}
              onChange={() => setError({ tokenError: false })}
              placeholder="Please enter token"
              className={`2xl:px-4 2xl:py-3 px-3 py-2 bg-white border  2xl:rounded-xl rounded-lg text-base text-grayTint3 w-full outline-none focus:border-primary transition-all duration-300 ${error.tokenError ? "border-red-600" : "border-grayTint2"}`}
            />
          </div>
          <div className="flex justify-center flex-col items-center">
            {error.empty ? (
              <p className="text-red-600 text-center">
                Please upload at least one menu
              </p>
            ) : error.tokenError ? (
              <p className="text-red-600 text-center">
                {"Please enter token"}
              </p>
            ) : null}
            <button
              className="relative lg:h-[55px] h-[45px] flex items-center justify-center w-full 2xl:py-4 lg:py-3 py-2 2xl:px-6 px-5 bg-primary border border-primary text-base font-semibold text-blackShade1 lg:rounded-xl rounded-lg transition-all duration-300 hover:bg-secondary hover:border-secondary hover:text-white mt-4"
              type="submit"
              onClick={handleSubmitMenu}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="spinner"></div>
              ) : (
                <span>Upload and Save</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Admin;
