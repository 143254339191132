import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import LunchMenu from "./pages/LunchMenu";
import ALaCarteMenu from "./pages/ALaCarteMenu";
import Drinks from "./pages/Drinks";
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsCondition from "./pages/TermsCondition";
import Admin from "pages/Admin";
import ContactUs from "./pages/ContactUs";
import { ScrollToTop } from "./components/common/ScrollToTop";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/lunch-menu" element={<LunchMenu />} />
        <Route path="/a-la-carte-menu" element={<ALaCarteMenu />} />
        <Route path="/drinks" element={<Drinks />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-condition" element={<TermsCondition />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/change-menu" element={<Admin />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnFocusLoss={false}
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
