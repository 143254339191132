import React from "react";
import { MainLayout } from "../layout/MainLayout/MainLayout";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import {
  aboutFood,
  aboutFoodImg,
  categories,
  gallery,
  heroSlider,
  records,
} from "../constants";
import checkImg from "../assets/images/icons/check.svg";
import { Link } from "react-router-dom";
import BookTableSection from "components/common/BookTable";

const Home = () => {

  return (
    <MainLayout>
      {/* HERO SECTION */}
      <section className="md:h-auto sm:h-[50vh] h-[70vh]">
        <Swiper
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          className="hero-slider h-full"
        >
          {heroSlider.map((sliderImg, sliderIndex) => {
            return (
              <SwiperSlide
                key={sliderIndex}
                className="h-auto relative before:content-[''] before:absolute before:bg-heroGradient before:w-full before:h-full before:top-0 before:left-0"
              >
                <img
                  src={sliderImg.img}
                  alt="food-image"
                  className="w-full h-full object-cover"
                />
              </SwiperSlide>
            );
          })}
          <div className="absolute top-2/4 left-0 z-[1] -translate-y-2/4 w-full">
            <div className="container lg:text-left text-center">
              <h1 className="2xl:text-[64px] xl:text-5xl lg:text-3xl md:text-2xl text-xl leading-[1.5] text-white lg:mb-11 mb-7 2xl:max-w-[952px] xl:max-w-3xl lg:max-w-lg font-bold">
                Embark on a Culinary Adventure at Flavour Heaven
              </h1>
              <div className="flex sm:flex-row flex-col w-[63%] sm:w-auto sm:mx-0 mx-auto sm:items-center items-stretch gap-4 lg:justify-start justify-center">
                <Link
                  to="/lunch-menu"
                  className="2xl:py-4 lg:py-3 py-2 2xl:px-6 px-5 bg-primary border border-primary text-base font-semibold text-blackShade1 lg:rounded-xl rounded-lg transition-all duration-300 hover:bg-secondary hover:border-secondary hover:text-white"
                >
                  Lunch Menu
                </Link>
                <Link
                  to="/a-la-carte-menu"
                  className="2xl:py-4 lg:py-3 py-2 2xl:px-6 px-5 bg-transparent border border-white text-base font-semibold text-white lg:rounded-xl rounded-lg transition-all duration-300 hover:bg-white hover:text-primary"
                >
                  A La Carte Menu
                </Link>
              </div>
            </div>
          </div>
        </Swiper>
      </section>

      {/* CATEGORIES */}
      <section className="3xl:py-52 2xl:py-44 xl:py-32 lg:py-20 md:py-16 py-14">
        <div className="container">
          <h3 className="capitalize text-blackShade1 2xl:text-5xl xl:text-4xl sm:text-3xl text-2xl font-bold text-center 2xl:mb-16 xl:mb-14 lg:mb-10 mb-8">
            <span className="text-primary">popular</span> categories
          </h3>
          <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 2xl:gap-11 xl:gap-9 lg:gap-7 gap-5">
            {categories.map((categoryItem, categoryIndex) => {
              return (
                <div
                  key={categoryIndex}
                  className="2xl:rounded-[20px] rounded-2xl overflow-hidden relative"
                >
                  <div className="absolute top-3 left-2/4 -translate-x-2/4 w-[90%] lg:h-[87%] md:h-[89%] sm:h-[86%] h-[92%] border-2 border-white 2xl:rounded-2xl rounded-xl"></div>
                  <img
                    src={categoryItem.img}
                    alt="category-image"
                    className="w-full object-cover"
                  />
                  <span className="absolute bottom-3 left-2/4 -translate-x-2/4 2xl:rounded-xl rounded-lg bg-primary md:text-lg text-base text-blackShade1 font-semibold text-center 2xl:p-3 p-2 block w-[82.5%] capitalize">
                    {categoryItem.categoryName}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* ABOUT */}
      <section className="3xl:pb-52 2xl:pb-44 xl:pb-32 lg:pb-20 md:pb-16 pb-14">
        <div className="container">
          <div className="flex lg:flex-row flex-col justify-between 2xl:gap-5 gap-12">
            <div className="2xl:w-[42%] lg:w-2/4 w-full shrink-0">
              <h3 className="2xl:text-5xl xl:text-4xl sm:text-3xl text-2xl font-bold text-blackShade1 md:mb-4 mb-3 leading[1.4]">
                A <span className="text-primary">Symphony</span> of Flavours
                Awaits
              </h3>
              <span className="block md:text-lg text-base text-gray md:mb-6 mb-4">
                From savory appetizers to decadent desserts, every dish is
                prepared with utmost care and attention to detail. We also offer
                a wide range of vegetarian and vegan options, catering to the
                diverse dietary needs of our customers.
              </span>
              {aboutFood.map((aboutItem, aboutIndex) => {
                return (
                  <div
                    key={aboutIndex}
                    className="flex items-start md:[&:not(:last-child)]:mb-4 mb-3"
                  >
                    <span className="flex items-center justify-center shrink-0 mr-3 mt-2">
                      <img src={checkImg} alt="check" />
                    </span>
                    <span className="md:text-lg text-base font-semibold leading-[1.4]">
                      {aboutItem.text}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="about-food-gallery 2xl:w-[51.6%] lg:w-2/4 w-full">
              {aboutFoodImg.map((aboutFoodItem, aboutFoodIndex) => {
                return (
                  <div
                    key={aboutFoodIndex}
                    className={`block xl:rounded-[20px] md:rounded-xl rounded-lg overflow-hidden ${aboutFoodItem.foodGridClass}`}
                  >
                    <img
                      src={aboutFoodItem.img}
                      alt="food-image"
                      className="w-full h-full object-cover"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {/* RECORDS */}
      <section className="bg-records bg-no-repeat bg-cover md:py-28 py-12 relative before:content-[''] before:absolute before:bg-[rgba(13,13,13,0.85)] before:w-full before:h-full before:left-0 before:top-0">
        <div className="container">
        <div className="md:flex grid grid-cols-2 justify-between 2xl:gap-36 xl:gap-20 lg:gap-12 md:gap-0 gap-8">
            {records.map((recordsItem, recordsIndex) => {
              return (
                <div key={recordsIndex} className="z-[1] text-center md:w-auto">
                  <div className="2xl:w-[120px] xl:w-24 lg:w-20 md:w-16 w-12 2xl:h-[120px] xl:h-24 lg:h-20 md:h-16 h-12 md:mb-10 mb-4 mx-auto">
                    <img
                      src={recordsItem.icon}
                      alt="icon"
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <span className="2xl:text-[40px] xl:text-3xl lg:text-2xl md:text-xl text-lg block text-white md:mb-3 mb-2 font-extrabold">
                    {recordsItem.numbers}
                  </span>
                  <span className="xl:text-2xl lg:text-xl md:text-lg text-base text-white capitalize sm:text-nowrap">

                    {recordsItem.name}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* RESERVE A TABLE */}
      <BookTableSection />

      {/* GALLERY */}
      <section className="3xl:py-52 2xl:py-44 xl:py-32 lg:py-20 md:py-16 py-14">
        <div className="container">
          <h3 className="text-blackShade1 2xl:text-5xl xl:text-4xl sm:text-3xl text-2xl font-bold 2xl:mb-16 xl:mb-14 lg:mb-10 mb-8 text-center">
            Gallery
          </h3>
          <div className="gallery-grid">
            {gallery.map((galleryItem, galleryIndex) => {
              return (
                <div
                  key={galleryIndex}
                  className={`block xl:rounded-2xl md:rounded-xl rounded-lg overflow-hidden ${galleryItem.gridClass}`}
                >
                  <img
                    src={galleryItem.img}
                    alt="gallery-image"
                    className="w-full h-full object-cover"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default Home;
