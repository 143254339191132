import React from "react";
import { MainLayout } from "../layout/MainLayout/MainLayout";
import Banner from "../components/common/Banner";
import bannerImg from "../assets/images/cover/about-banner.jpg";
import { aboutUsContent } from "../constants";

const AboutUs = () => {
	return (
		<MainLayout>
			<Banner heading="About Us" url={bannerImg} gradientClass="bg-aboutBg" />
			<section className="text-blackShade1 3xl:pb-52 2xl:pb-44 xl:pb-36 lg:pb-28 md:pb-24 pb-20 pt-12">
				<div className="container">
					<div className="bg-smokeWhite 2xl:rounded-[32px] xl:rounded-3xl rounded-2xl mx-auto xl:max-w-[80%] 2xl:p-12 xl:p-9 lg:p-7 md:p-6 p-5">
						{aboutUsContent.map((aboutItem, aboutIndex) => {
							return (
								<div key={aboutIndex} className="mb-6">
									<h5 className="md:text-xl text-lg font-semibold text-blackShade1 mb-1">{aboutItem.title}</h5>
									<span className="block md:text-lg text-base text-blackShade1">{aboutItem.details}</span>
								</div>
							);
						})}
						<h4 className="md:text-2xl text-xl text-primary font-semibold">Step into Flavour Heaven. Your taste buds will thank you.</h4>
					</div>
				</div>
			</section>
		</MainLayout>
	);
};

export default AboutUs;
