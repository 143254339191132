import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";

export const MainLayout = ({ children, pageWrapper }) => {
	return (
		<div className={pageWrapper && pageWrapper}>
			<Header />
			{children}
			<Footer />
		</div>
	);
};
