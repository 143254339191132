import bannerImg1 from "../assets/images/cover/hero-banner-1.jpg";
import bannerImg2 from "../assets/images/cover/hero-banner-2.jpg";
import bannerImg3 from "../assets/images/cover/hero-banner-3.jpg";
import fishSpecial from "../assets/images/cover/fish-special.jpg";
import indianTaste from "../assets/images/cover/indian-taste.jpg";
import karelian from "../assets/images/cover/karelian.jpg";
import thaiSpecial from "../assets/images/cover/thai-special.jpg";
import chefImg from "../assets/images/icons/chef.svg";
import foodImg from "../assets/images/icons/food.svg";
import experienceImg from "../assets/images/icons/experience.svg";
import customerImg from "../assets/images/icons/customers.svg";
import gallery3 from "../assets/images/cover/gallery-3.jpg";
import gallery4 from "../assets/images/cover/gallery-4.jpg";
import gallery5 from "../assets/images/cover/gallery-5.jpg";
import gallery6 from "../assets/images/cover/gallery-6.jpg";
import gallery7 from "../assets/images/cover/gallery-7.jpg";
import gallery8 from "../assets/images/cover/gallery-8.jpg";
import food1 from "../assets/images/cover/food-1.jpg";
import food2 from "../assets/images/cover/food-2.jpg";
import food3 from "../assets/images/cover/food-3.jpg";
import food4 from "../assets/images/cover/food-4.jpg";
import location from "../assets/images/location.svg";
import call from "../assets/images/call.svg";
import mail from "../assets/images/mail.svg";

// HEADER LINK
export const headerLink = [
	{
		navName: "Home",
		navPath: "/",
	},
	{
		navName: "Lunch Menu",
		navPath: "/lunch-menu",
	},
	{
		navName: "A La Carte Menu",
		navPath: "/a-la-carte-menu",
	},
	{
		navName: "Drinks",
		navPath: "/drinks",
	},
	{
		navName: "About us",
		navPath: "/about-us",
	},
	{
		navName: "Contact us",
		navPath: "/contact-us",
	},
];

// HOME HERO SLIDER
export const heroSlider = [
	{
		img: bannerImg1,
	},
	{
		img: bannerImg2,
	},
	{
		img: bannerImg3,
	},
];

// CATEGORIES
export const categories = [
	{
		img: fishSpecial,
		categoryName: "fish specials",
	},
	{
		img: indianTaste,
		categoryName: "the indian taste",
	},
	{
		img: karelian,
		categoryName: "karelian",
	},
	{
		img: thaiSpecial,
		categoryName: "thai specials",
	},
];

// ABOUT FOOD
export const aboutFood = [
	{
		text: "Provide diverse menu of dishes from different cuisines",
	},
	{
		text: "We use freshest and highest quality ingredients",
	},
	{
		text: "Offers Private dining options for intimate gatherings or events",
	},
];

// RECORDS
export const records = [
	{
		icon: chefImg,
		numbers: "20+",
		name: "professional chefs",
	},
	{
		icon: foodImg,
		numbers: "100+",
		name: "items of food",
	},
	{
		icon: experienceImg,
		numbers: "25+",
		name: "years of experience",
	},
	{
		icon: customerImg,
		numbers: "220",
		name: "happy customers",
	},
];

// GALLERY
export const gallery = [
	{
		gridClass: "img-1",
		img: bannerImg1,
	},
	{
		gridClass: "img-2",
		img: fishSpecial,
	},
	{
		gridClass: "img-3",
		img: gallery3,
	},
	{
		gridClass: "img-4",
		img: gallery4,
	},
	{
		gridClass: "img-5",
		img: gallery5,
	},
	{
		gridClass: "img-6",
		img: gallery6,
	},
	{
		gridClass: "img-7",
		img: gallery7,
	},
	{
		gridClass: "img-8",
		img: gallery8,
	},
];

// ABOUT FOOD GALLERY
export const aboutFoodImg = [
	{
		foodGridClass: "food-1",
		img: food1,
	},
	{
		foodGridClass: "food-2",
		img: food2,
	},
	{
		foodGridClass: "food-3",
		img: food4,
	},
	{
		foodGridClass: "food-4",
		img: food3,
	},
];

// ABOUT US PAGE CONTENT
export const aboutUsContent = [
	{
		title: "Flavour Heaven: A Global Feast for the Senses",
		details: "Imagine a vibrant tapestry woven from the culinary traditions of the world. Flavour Heaven, nestled in the heart of Helsinki, Finland, isn't just a restaurant; it's a portal to a world of unforgettable tastes. Here, you embark on a delicious odyssey, savoring the fiery notes of exotic spices, the comforting warmth of familiar classics, and the delicate artistry of international cuisines.",
	},
	{
		title: "A Symphony of Flavours",
		details: "Our menu is a dynamic orchestra, each dish a unique instrument playing its part in a harmonious symphony. We source the freshest ingredients, ensuring vibrant colours, tantalizing aromas, and textures that dance on your tongue. Whether you crave the robust comfort of European classics or the adventurous intrigue of Asian spices, our menu caters to every culinary whim.",
	},
	{
		title: "More Than Just a Meal: An Immersive Experience",
		details: "Flavour Heaven goes beyond simply satisfying your hunger. Our ambience is an extension of the culinary adventure. Imagine a warm, inviting space where exposed brick walls whisper tales of faraway lands, and vibrant murals transport you to bustling marketplaces brimming with exotic spices. Our friendly and knowledgeable staff ensures your experience is seamless, guiding you through the menu and recommending the perfect beverage pairings.",
	},
	{
		title: "Liquid Delights to Elevate Your Journey",
		details: "Our bar is not merely an afterthought; it's an integral part of the journey. We offer a meticulously curated selection of beverages designed to elevate your culinary exploration. Refreshing cocktails, crafted with premium spirits, perfectly complement the fiery notes of your chosen dish. Or, perhaps a robust glass of wine, carefully chosen from our international list, enhances the depth of flavour in a European classic.",
	},
	{
		title: "Escape the Ordinary, Embrace the Extraordinary",
		details: "Flavour Heaven is an invitation for the curious palate, a playground for the adventurous eater, and a haven for those seeking a culinary escape. Whether you're a seasoned explorer with a well-worn passport or a first-time adventurer, we invite you to embark on a voyage of delicious discovery. Join us, and let your taste buds take flight on a global adventure.",
	},
];

// CONTACT US
export const contactUs = [
	{
		icon: location,
		cta: "https://maps.app.goo.gl/6RKfnsCrMBGjM44x7?g_st=iw",
		ctaText: "Meritorni, 1st floor, Haapaniemenkatu 7-9 B, 00530 Helsinki",
	},
	{
		icon: call,
		cta: "tel:(+358)207314020",
		ctaText: "(+358) 20 7314020",
	},
	{
		icon: mail,
		cta: "mailto:info@flavourheaven.fi",
		ctaText: "info@flavourheaven.fi",
	},
]