import { Link } from "react-router-dom";

export const AdminLayout = ({ children, pageWrapper }) => {
  return (
    <div className={pageWrapper && pageWrapper}>
      {children}
      <div className="bg-primaryLight w-full">
        <div className="container">
          <div className="py-4">
            <ul className="flex flex-wrap justify-center gap-6">
              <li className="relative text-gray text-[14px] after:content-'' after:absolute after:h-full after:bg-[#D9D9D9] after:w-[1px] after:ml-[12px]">
                Copyright © 2024 Flavour Heaven
              </li>
              <li className="relative text-gray text-[14px] after:content-'' after:absolute after:h-full after:bg-[#D9D9D9] after:w-[1px] after:ml-[12px]">
                All Rights Reserved
              </li>
              <li className="relative text-[14px] after:content-'' after:absolute after:h-full after:bg-[#D9D9D9] after:w-[1px] after:ml-[12px]">
                <Link
                  to="/terms-and-condition"
                  className="text-gray hover:text-primary transition-all"
                >
                  Terms and Conditions
                </Link>
              </li>
              <li className="text-[14px]">
                <Link
                  to="/privacy-policy"
                  className="text-gray hover:text-primary transition-all"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
