export const formatDateDDMMYYYY = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

export function toTwoDigits(number) {
  return number.toString().padStart(2, "0");
}

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};

const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/;

export function validateDate(dateString) {
  return dateRegex.test(dateString);
}