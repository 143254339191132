import React from "react";
import { MainLayout } from "../layout/MainLayout/MainLayout";

const TermsCondition = () => {
	return (
		<MainLayout>
			<section className="3xl:pb-52 2xl:pb-44 xl:pb-36 lg:pb-28 md:pb-24 pb-20 lg:pt-20 pt-12">
				<div className="container">
					<h3 className="text-blackShade1 2xl:text-5xl xl:text-4xl sm:text-3xl text-2xl font-bold text-center 2xl:mb-16 xl:mb-14 lg:mb-10 mb-8">Flavour Heaven Restaurant - Terms & Conditions</h3>
					<div className="bg-smokeWhite 2xl:rounded-[32px] xl:rounded-3xl rounded-2xl mx-auto xl:max-w-[80%] 2xl:p-12 xl:p-9 lg:p-7 md:p-6 p-5">
						<div className="mb-6">
							<h5 className="md:text-xl text-lg font-semibold text-blackShade1 mb-3">Welcome to Flavour Heaven!</h5>
							<span className="text-base text-blackShade1 block">These Terms & Conditions ("Terms") govern your use of the Flavour Heaven website (the "Website") and any online services offered by Flavour Heaven Restaurant ("Flavour Heaven", "we", "us", or "our"). By accessing or using the Website, you agree to be bound by these Terms. If you disagree with any part of the Terms, please do not use the Website.</span>
						</div>
						<ol className="custom-counter mb-6">
							<li className="mb-6 text-base text-blackShade1 font-bold head-li">
								Use of the Website
								<ol className="custom-counter mt-3">
									<li className="text-base text-blackShade1 font-normal mb-2">You may use the Website for personal, non-commercial purposes only.</li>
									<li className="text-base text-blackShade1 font-normal mb-2">You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, content, software, products, or services obtained from the Website.</li>
									<li className="text-base text-blackShade1 font-normal">You agree to use the Website in a lawful manner and in accordance with all applicable Finnish laws and regulations.</li>
								</ol>
							</li>
							<li className="mb-6 text-base text-blackShade1 font-bold head-li">
								Online Ordering
								<ol className="custom-counter mt-3">
									<li className="text-base text-blackShade1 font-normal mb-2">You may be able to place online orders for takeaway or delivery through the Website.</li>
									<li className="text-base text-blackShade1 font-normal mb-2">All prices listed on the Website are in Euros (€) and include Finnish VAT (value-added tax) at the current rate. Prices are subject to change without notice.</li>
									<li className="text-base text-blackShade1 font-normal mb-2">We reserve the right to cancel or modify your order at any time, in whole or in part, due to unforeseen circumstances (e.g., ingredient availability). We will attempt to notify you by email or phone in such cases.</li>
									<li className="text-base text-blackShade1 font-normal mb-2"> Payment for online orders can be made through our secure payment gateway. We accept major credit and debit cards.</li>
									<li className="text-base text-blackShade1 font-normal">You agree to provide accurate and complete information when placing an online order.</li>
								</ol>
							</li>
							<li className="mb-6 text-base text-blackShade1 font-bold head-li">
								Disclaimer
								<ol className="custom-counter mt-3">
									<li className="text-base text-blackShade1 font-normal mb-2">The Website and all information, content, and materials provided on the Website are provided "as is" and without warranties of any kind, express or implied. Flavour Heaven disclaims all warranties, express or implied, including, but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement.</li>
									<li className="text-base text-blackShade1 font-normal mb-2"> Flavour Heaven does not warrant that the Website will be uninterrupted, error-free, or free of viruses or other harmful components.</li>
									<li className="text-base text-blackShade1 font-normal">You assume the entire cost of all necessary servicing, repair, or correction in case of damage or malfunction caused by your use of the Website.</li>
								</ol>
							</li>
							<li className="mb-6 text-base text-blackShade1 font-bold head-li">
								Limitation of Liability
								<ol className="custom-counter mt-3">
									<li className="text-base text-blackShade1 font-normal">To the fullest extent permitted by applicable Finnish law, Flavour Heaven shall not be liable for any damages arising out of or related to your use of the Website, including, but not limited to, direct, indirect, incidental, consequential, punitive, or special damages.</li>
								</ol>
							</li>
							<li className="mb-6 text-base text-blackShade1 font-bold head-li">
								Intellectual Property
								<ol className="custom-counter mt-3">
									<li className="text-base text-blackShade1 font-normal mb-2">The Website and all content on the Website, including trademarks, copyrights, and other intellectual property rights, are the property of Flavour Heaven or its licensors.</li>
									<li className="text-base text-blackShade1 font-normal">You may not use any trademarks, service marks, or copyrights displayed on the Website without our prior written consent.</li>
								</ol>
							</li>
							<li className="mb-6 text-base text-blackShade1 font-bold head-li">
								Changes to the Terms
								<ol className="custom-counter mt-3">
									<li className="text-base text-blackShade1 font-normal">We may revise these Terms at any time by updating this page. You are bound by any revisions and should therefore periodically visit this page to review the current Terms.</li>
								</ol>
							</li>
							<li className="mb-6 text-base text-blackShade1 font-bold head-li">
								Governing Law
								<ol className="custom-counter mt-3">
									<li className="text-base text-blackShade1 font-normal">These Terms shall be governed by and construed in accordance with the laws of Finland.</li>
								</ol>
							</li>
							<li className="mb-6 text-base text-blackShade1 font-bold head-li">
								Entire Agreement
								<ol className="custom-counter mt-3">
									<li className="text-base text-blackShade1 font-normal">These Terms constitute the entire agreement between you and Flavour Heaven regarding your use of the Website.</li>
								</ol>
							</li>
							<li className="text-base text-blackShade1 font-bold head-li">
								Contact Us
								<ol className="custom-counter mt-3">
									<li className="text-base text-blackShade1 font-normal">
										If you have any questions about these Terms, please contact us at: <a href="mailto:contact@flavourheaven.fi">contact@flavourheaven.fi</a>
									</li>
								</ol>
							</li>
						</ol>
						<h4 className="md:text-2xl text-xl text-primary font-semibold">Thank you for visiting Flavour Heaven!</h4>
					</div>
				</div>
			</section>
		</MainLayout>
	);
};

export default TermsCondition;
