import React from "react";
import { MainLayout } from "../layout/MainLayout/MainLayout";

const PrivacyPolicy = () => {
	return (
		<MainLayout>
			<section className="3xl:pb-52 2xl:pb-44 xl:pb-36 lg:pb-28 md:pb-24 pb-20 lg:pt-20 pt-12">
				<div className="container">
					<h3 className="text-blackShade1 2xl:text-5xl xl:text-4xl sm:text-3xl text-2xl font-bold text-center 2xl:mb-16 xl:mb-14 lg:mb-10 mb-8">Flavour Heaven - Privacy Policy</h3>
					<div className="bg-smokeWhite 2xl:rounded-[32px] xl:rounded-3xl rounded-2xl mx-auto xl:max-w-[80%] 2xl:p-12 xl:p-9 lg:p-7 md:p-6 p-5">
						<div className="mb-6">
							<span className="md:text-lg text-base text-blackShade1 block">Flavour Heaven ("we," "us," or "our") is committed to protecting the privacy of our users ("you" or "your"). This Privacy Policy explains what information we collect when you use our website (“https://flavourheaven.fi/”) and how we use that information.</span>
						</div>
						<div className="mb-6">
							<h5 className="md:text-xl text-lg font-semibold text-blackShade1 mb-3">Information We Collect:</h5>
							<ul className="list-disc list-inside md:pl-7 pl-4">
								<li className="md:text-lg text-base text-blackShade1 mb-2">
									<span className="font-semibold">Non-Personally Identifiable Information:</span> We collect non-personally identifiable information ("Non-PII") automatically when you visit the Website. This may include information such as your IP address, browser type, operating system, referring URL, pages visited on the Website, and the time of your visit. This information is used to improve the functionality of the Website and understand how visitors use it.
								</li>
								<li className="md:text-lg text-base text-blackShade1">
									<span className="font-semibold">Personally Identifiable Information (PII):</span> We only collect PII when you voluntarily provide it, such as when you make a reservation online, sign up for our email list, or contact us through the Website. This information may include your name, email address, phone number, and any dietary restrictions you provide.
								</li>
							</ul>
						</div>
						<div className="mb-6">
							<h5 className="md:text-xl text-lg font-semibold text-blackShade1 mb-3">Use of Information:</h5>
							<span className="md:text-lg text-base text-blackShade1 mb-2 block">We use the information we collect for the following purposes:</span>
							<ul className="list-disc list-inside md:pl-7 pl-4">
								<li className="md:text-lg text-base text-blackShade1 mb-1">To process your online reservation requests.</li>
								<li className="md:text-lg text-base text-blackShade1 mb-1">To send you marketing communications, such as newsletters and special offers (with your consent).</li>
								<li className="md:text-lg text-base text-blackShade1 mb-1">To respond to your inquiries and requests.</li>
								<li className="md:text-lg text-base text-blackShade1 mb-1">To improve the Website and your experience on it.</li>
								<li className="md:text-lg text-base text-blackShade1">To comply with legal and regulatory requirements.</li>
							</ul>
						</div>
						<div className="mb-6">
							<h5 className="md:text-xl text-lg font-semibold text-blackShade1 mb-3">Data Retention:</h5>
							<span className="md:text-lg text-base text-blackShade1 block">We will retain your information for as long as it is necessary to fulfill the purposes described above, or until you request that we delete your information.</span>
						</div>
						<div className="mb-6">
							<h5 className="md:text-xl text-lg font-semibold text-blackShade1 mb-3">Sharing of Information:</h5>
							<span className="md:text-lg text-base text-blackShade1 mb-2 block">We will not share your PII with third parties except in the following circumstances:</span>
							<ul className="list-disc list-inside md:pl-7 pl-4">
								<li className="md:text-lg text-base text-blackShade1 mb-1">With service providers who help us operate the Website and provide our services, such as reservation systems and email marketing platforms. These service providers are contractually obligated to keep your information confidential.</li>
								<li className="md:text-lg text-base text-blackShade1 mb-1">To comply with legal and regulatory requirements or to respond to a subpoena, court order, or other legal process.</li>
								<li className="md:text-lg text-base text-blackShade1 mb-1">In the event of a merger, acquisition, or other business transaction involving Flavour Heaven, your information may be transferred to the new owner.</li>
							</ul>
						</div>
						<div className="mb-6">
							<h5 className="md:text-xl text-lg font-semibold text-blackShade1 mb-3">Your Rights:</h5>
							<span className="md:text-lg text-base text-blackShade1 mb-2 block">You have the following rights with respect to your PII:</span>
							<ul className="list-disc list-inside md:pl-7 pl-4">
								<li className="md:text-lg text-base text-blackShade1 mb-1">The right to access your PII.</li>
								<li className="md:text-lg text-base text-blackShade1 mb-1">The right to rectify any inaccuracies in your PII.</li>
								<li className="md:text-lg text-base text-blackShade1 mb-1">The right to request the erasure of your PII.</li>
								<li className="md:text-lg text-base text-blackShade1 mb-1">The right to restrict the processing of your PII.</li>
								<li className="md:text-lg text-base text-blackShade1 mb-1">The right to object to the processing of your PII.</li>
								<li className="md:text-lg text-base text-blackShade1 mb-1">The right to data portability (to receive your PII in a machine-readable format).</li>
							</ul>
							<span className="md:text-lg text-base text-blackShade1 block">If you wish to exercise any of these rights, please contact us using the information below.</span>
						</div>
						<div className="mb-6">
							<h5 className="md:text-xl text-lg font-semibold text-blackShade1 mb-3">Security:</h5>
							<span className="md:text-lg text-base text-blackShade1 block">We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no website is 100% secure, and we cannot guarantee the security of your information.</span>
						</div>
						<div className="mb-6">
							<h5 className="md:text-xl text-lg font-semibold text-blackShade1 mb-3">Children's Privacy:</h5>
							<span className="md:text-lg text-base text-blackShade1 block">The Website is not directed at children under the age of 13. We do not knowingly collect PII from children under 13. If you are a parent or guardian and you believe your child has provided us with PII, please contact us and we will delete that information from our records.</span>
						</div>
						<div className="mb-6">
							<h5 className="md:text-xl text-lg font-semibold text-blackShade1 mb-3">Changes to This Privacy Policy:</h5>
							<span className="md:text-lg text-base text-blackShade1 block">We may update this Privacy Policy from time to time. We will post any changes on this page. We encourage you to review this Privacy Policy periodically for the latest information.</span>
						</div>
						<div className="mb-6">
							<h5 className="md:text-xl text-lg font-semibold text-blackShade1 mb-3">Contact Us:</h5>
							<span className="md:text-lg text-base text-blackShade1 mb-2 block">If you have any questions about this Privacy Policy, please contact us at:</span>
							<span className="md:text-lg text-base text-blackShade1 mb-2 block">Flavour Heaven </span>
							<div className="flex items-center mb-1">
								<span className="md:text-lg text-base text-blackShade1 block mr-1">Phone no:</span>
								<a href="tel:(414) 687 - 5892" className="md:text-lg text-base text-blackShade1 transition-all duration-300 hover:text-primary">
									(414) 687 - 5892
								</a>
							</div>
							<div className="flex items-center mb-1">
								<span className="md:text-lg text-base text-blackShade1 block mr-1">Email:</span>
								<a href="mailto:contact@flavourheaven.fi" className="md:text-lg text-base text-blackShade1 transition-all duration-300 hover:text-primary">
									contact@flavourheaven.fi
								</a>
							</div>
						</div>
						<div>
							<h5 className="md:text-xl text-lg font-semibold text-blackShade1 mb-3">Finish Law:</h5>
							<span className="md:text-lg text-base text-blackShade1 block">This Privacy Policy is subject to the laws of Finland, including the General Data Protection Regulation (GDPR).</span>
						</div>
					</div>
				</div>
			</section>
		</MainLayout>
	);
};

export default PrivacyPolicy;
