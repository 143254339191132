import React, { useEffect, useState } from "react";
import { MainLayout } from "../layout/MainLayout/MainLayout";
import Banner from "../components/common/Banner";
import bannerImg from "../assets/images/cover/hero-banner-1.jpg";
import { contactUs } from "../constants";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
const libraries = ["places"];

const center = {
  lat: 60.17982598883974,
  lng: 24.95875574639971,
};

const ContactUs = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCU7LQZWZv9Z-YjnWtcEgwcn_nS202xeQo",
    libraries,
  });
  const [mapContainerStyle, setMapContainerStyle] = useState({
    width: "100%",
    minWidth: "192px",
    minHeight: window.innerWidth >= 768 ? "100%" : "80vh",
    maxHeight: "100%",
    borderRadius: "32px",
  });

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setMapContainerStyle((prev) => ({
        ...prev,
        minHeight: newWidth >= 768 ? "100%" : "80vh",
      }));
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <MainLayout>
      <Banner
        heading="Contact Us"
        url={bannerImg}
        gradientClass="bg-contactBg"
      />
      <section className="2xl:pt-40 3xl:pb-52 2xl:pb-44 xl:py-36 lg:py-28 md:py-24 py-20">
        <div className="container">
          <h3 className="2xl:text-5xl xl:text-4xl sm:text-3xl text-2xl font-bold text-center text-blackShade1 lg:mb-11 mb-8">
            Get in touch
          </h3>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 xl:gap-5 gap-3 3xl:mb-44 2xl:mb-36 xl:mb-24 lg:mb-16 mb-12">
            {contactUs.map((contactItem, contactIndex) => {
              return (
                <div
                  key={contactIndex}
                  className="flex flex-col items-center min-h-48 min-w-48 justify-center bg-grayTint1 2xl:rounded-3xl xl:rounded-2xl lg:rounded-xl rounded-lg xl:py-6 xl:px-8 py-4 px-3"
                >
                  <div className="md:mb-4 mb-3 md:w-8 md:h-8 w-6 h-6">
                    <img
                      src={contactItem.icon}
                      alt="icon"
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <a
                    href={contactItem.cta}
                    className="block text-center xl:text-xl text-base text-secondary transition-all duration-300 hover:text-primary"
                  >
                    {contactItem.ctaText}
                  </a>
                </div>
              );
            })}
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
            <div className="md:min-w-48 md:min-h-[70vh]">
              {!isLoaded ? (
                <>Loading Maps</>
              ) : loadError ? (
                <>Error Loading Map</>
              ) : (
                <GoogleMap
                  className="md:min-w-48 md:min-h-[70vh]"
                  mapContainerStyle={mapContainerStyle}
                  zoom={18}
                  center={center}
                  options={{
                    maxZoom: 18,
                  }}
                >
                  <Marker position={center} />
                </GoogleMap>
              )}
            </div>
            <div>
              <h3 className="leading-[1.4] 2xl:text-5xl xl:text-4xl sm:text-3xl text-2xl font-bold text-center text-blackShade1 mb-2">
                <span className="text-primary">Send</span> A Message
              </h3>
              <span className="text-center font-medium text-gray text-lg block 2xl:mb-12 lg:mb-8 mb-6">
                Contact us if you have any queries or need any help
              </span>
              <form className="2xl:max-w-lg md:max-w-md mx-auto 2xl:rounded-[32px] xl:rounded-3xl rounded-2xl bg-primaryTint1 2xl:p-12 xl:p-9 lg:p-7 md:p-6 p-5">
                <div className="md:mb-4 mb-3">
                  <label
                    htmlFor="fullName"
                    className="text-sm block mb-2 text-gray capitalize"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    placeholder="Enter your full name"
                    className="2xl:px-4 2xl:py-3 px-3 py-2 bg-white border border-grayTint2 2xl:rounded-xl rounded-lg text-base text-grayTint3 w-full outline-none focus:border-primary transition-all duration-300"
                  />
                </div>
                <div className="md:mb-4 mb-3">
                  <label
                    htmlFor="email"
                    className="text-sm block mb-2 text-gray capitalize"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    className="2xl:px-4 2xl:py-3 px-3 py-2 bg-white border border-grayTint2 2xl:rounded-xl rounded-lg text-base text-grayTint3 w-full outline-none focus:border-primary transition-all duration-300"
                  />
                </div>
                <div className="md:mb-4 mb-3">
                  <label
                    htmlFor="subject"
                    className="text-sm block mb-2 text-gray capitalize"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    id="subject"
                    placeholder="Write the subject"
                    className="2xl:px-4 2xl:py-3 px-3 py-2 bg-white border border-grayTint2 2xl:rounded-xl rounded-lg text-base text-grayTint3 w-full outline-none focus:border-primary transition-all duration-300"
                  />
                </div>
                <div className="md:mb-8 mb-6">
                  <label
                    htmlFor="message"
                    className="text-sm block mb-2 text-gray capitalize"
                  >
                    Message
                  </label>
                  <textarea
                    id="fullName"
                    placeholder="Write your message"
                    className="2xl:px-4 2xl:py-3 px-3 py-2 bg-white border border-grayTint2 2xl:rounded-xl rounded-lg text-base text-grayTint3 w-full outline-none focus:border-primary transition-all duration-300 resize-none h-28"
                  />
                </div>
                <button
                  className="bg-primary text-base font-semibold text-blackShade1 text-center w-full p-3 lg:rounded-xl rounded-lg transition-all duration-300 hover:bg-secondary hover:border-secondary hover:text-white"
                  onClick={(e) => e.preventDefault()}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default ContactUs;
